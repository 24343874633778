import React from "react";

const Certificate = () => {
  const certificate = [
    {
      name: "Javascript Certiport",
      image: "javascript.jpg",
      link: "https://drive.google.com/file/d/1KsIVTtWOswUiTGs4KXNzWHP8vJS6o9kZ/view?usp=sharing",
    },
    {
      name: "Tefa Industry Class",
      image: "tefa.jpg",
      link: "https://drive.google.com/file/d/1FZh2LDyJnX7VFEAaRxcFiRX2R0k2lE7C/view?usp=sharing",
    },
    {
      name: "View more my certificate",
      image: null,
      link: "https://drive.google.com/drive/folders/1FUAPbn9D4X7W14Z02VGqNRgf5MqjiwF_?usp=sharing",
      desc: null,
      buttonText: "View More",
    },
  ];

  return (
    <section
      id="certificate"
      className="py-20 px-8 md:px-16 lg:px-32 xl:px-64 bg-primary text-primary h-auto"
    >
      <div>
        <h2 className="text-center text-4xl font-bold mb-12 text-secondary">
          Certificate
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {certificate.map((certificate, index) => (
          <a
            key={index}
            href={certificate.link}
            target="_blank"
            rel="noopener noreferrer"
            className="item-tech flex-col cursor-pointer items-center gap-4 p-4 border bg-secondary rounded-lg transition-transform transform hover:-translate-y-2"
          >
            {certificate.image ? (
              <div className="flex h-48 w-72 items-center justify-center lg:h-48 lg:w-72">
                <img
                  src={require(`../assets/image/${certificate.image}`)}
                  alt={`${certificate.name}`}
                  className="h-full w-full object-contain justify-center"
                />
              </div>
            ) : (
              <div className="flex h-48 w-72 items-center justify-center lg:h-48 lg:w-72">
                <button className="px-4 py-2 bg-secondary border-2  text-white rounded-lg">
                  {certificate.buttonText}
                </button>
              </div>
            )}
            <h3 className="text-xl lg:text-2xl text-center">{certificate.name}</h3>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Certificate;
